/* eslint-disable */
/* File is auto generated by TypeScriptGenerator version 9.6.3 - don't edit */
import { createRequestService } from "@templafy/request-service/RequestService";
import * as Contracts from "./Contracts";

export const requestService = createRequestService({
    baseUrl: "/auth",
    systemName: "authentication",
});

type ApiRequestOptions = {
    signal: AbortSignal
};

export const Api = {
    Password: {
        /**
        * @external Templafy.Authentication.WebApi.Private.Users.PasswordController.RequestPasswordReset
        */
        requestPasswordReset: (requestPasswordResetDto: Contracts.RequestPasswordResetDto, options?: ApiRequestOptions) => requestService.request<Contracts.RedirectResponseDto>("post", "password/request-reset", {signal: options?.signal, bodyParameters: {requestPasswordResetDto}}),
        /**
        * @external Templafy.Authentication.WebApi.Private.Users.PasswordController.ResetPassword
        */
        resetPassword: (resetPasswordDto: Contracts.ResetPasswordDto, options?: ApiRequestOptions) => requestService.request<Contracts.RedirectResponseDto>("put", "password", {signal: options?.signal, bodyParameters: {resetPasswordDto}}),
        /**
        * @external Templafy.Authentication.WebApi.Private.Users.PasswordController.ValidateResetPasswordLink
        */
        validateResetPasswordLink: (passwordResetId?: string, options?: ApiRequestOptions) => requestService.request<Contracts.RedirectResponseDto>("get", "password/validate-reset-link", {signal: options?.signal, queryParameters: {passwordResetId}}),
    },
    Users: {
        /**
        * @external Templafy.Authentication.WebApi.Private.Users.UsersController.SearchAccessibleTenantsByEmail
        */
        searchAccessibleTenantsByEmail: (dto: Contracts.SearchAccessibleTenantsByEmailDto, options?: ApiRequestOptions) => requestService.request<Contracts.UserTenantDto[]>("post", "users/tenants/search", {signal: options?.signal, bodyParameters: {dto}}),
    },
    UserInvite: {
        /**
        * @external Templafy.Authentication.WebApi.Private.UserInvites.UserInviteController.GetInvite
        */
        getInvite: (id?: string, returnUrl?: string, options?: ApiRequestOptions) => requestService.request<Contracts.RedirectResponseDto>("get", "user-invites/{id}", {signal: options?.signal, queryParameters: {id, returnUrl}}),
        /**
        * @external Templafy.Authentication.WebApi.Private.UserInvites.UserInviteController.AcceptInvite
        */
        acceptInvite: (id: string, acceptInviteDto: Contracts.AcceptInviteDto, options?: ApiRequestOptions) => requestService.request<Contracts.RedirectResponseDto>("put", "user-invites/{id}/accept", {signal: options?.signal, bodyParameters: {id, acceptInviteDto}}),
    },
    UserIdentification: {
        /**
        * @external Templafy.Authentication.WebApi.Private.UserIdentification.UserIdentificationController.Identify
        */
        identify: (dto?: Contracts.UserIdentificationDto, options?: ApiRequestOptions) => requestService.request<Contracts.IActionResult>("post", "identify", {signal: options?.signal, format: "FormData", bodyParameters: {dto}}),
    },
    UserActivation: {
        /**
        * @external Templafy.Authentication.WebApi.Private.PendingUsers.UserActivationController.VerifyPendingUser
        */
        verifyPendingUser: (activationId?: string, options?: ApiRequestOptions) => requestService.request<Contracts.RedirectResponseDto>("get", "user-activation", {signal: options?.signal, queryParameters: {activationId}}),
        /**
        * @external Templafy.Authentication.WebApi.Private.PendingUsers.UserActivationController.ActivateUser
        */
        activateUser: (userActivationDto: Contracts.UserActivationDto, options?: ApiRequestOptions) => requestService.request<Contracts.RedirectResponseDto>("post", "user-activation", {signal: options?.signal, bodyParameters: {userActivationDto}}),
    },
    AuthenticationMethods: {
        /**
        * @external Templafy.Authentication.WebApi.Private.Authentication.AuthenticationMethodsController.GetAll
        */
        getAll: (tenantId?: string, options?: ApiRequestOptions) => requestService.request<Contracts.AuthenticationMethodDto[]>("get", "authentication-methods", {signal: options?.signal, queryParameters: {tenantId}}),
    },
    AuthenticationToken: {
        /**
        * @external Templafy.Authentication.WebApi.Private.Authentication.AuthenticationTokenController.SignedInStart
        */
        signedInStart: (returnUrl?: string, options?: ApiRequestOptions) => requestService.request<Contracts.IActionResult>("get", "AuthenticationToken/signed-in", {signal: options?.signal, queryParameters: {returnUrl}}),
        /**
        * @external Templafy.Authentication.WebApi.Private.Authentication.AuthenticationTokenController.SignedInEnd
        */
        signedInEnd: (refreshToken?: string, returnUri?: string, options?: ApiRequestOptions) => requestService.request<Contracts.IActionResult>("post", "AuthenticationToken/signed-in", {signal: options?.signal, format: "FormData", bodyParameters: {refreshToken, returnUri}}),
    },
    SignIn: {
        /**
        * @external Templafy.Authentication.WebApi.Private.Authentication.SignInController.SignInOnTenantDomain
        */
        signInOnTenantDomain: (signInDto?: Contracts.SignInDto, options?: ApiRequestOptions) => requestService.request<Contracts.IActionResult>("get", "SignIn/sign-in", {signal: options?.signal, queryParameters: {signInDto}}),
        /**
        * @external Templafy.Authentication.WebApi.Private.Authentication.SignInController.SignIn
        */
        signIn: (signInDto?: Contracts.SignInDto, options?: ApiRequestOptions) => requestService.request<Contracts.IActionResult>("get", "SignIn/sign-in", {signal: options?.signal, queryParameters: {signInDto}}),
        /**
        * @external Templafy.Authentication.WebApi.Private.Authentication.SignInController.SysOpsSignIn
        */
        sysOpsSignIn: (signInDto?: Contracts.SignInDto, options?: ApiRequestOptions) => requestService.request<Contracts.IActionResult>("get", "SignIn/sysops-sign-in", {signal: options?.signal, queryParameters: {signInDto}}),
    },
    EmailAuthentication: {
        /**
        * @external Templafy.Authentication.WebApi.Private.Authentication.Email.EmailAuthenticationController.SignIn
        */
        signIn: (emailSignInDto: Contracts.EmailSignInDto, options?: ApiRequestOptions) => requestService.request<Contracts.RedirectResponseDto>("post", "email/sign-in", {signal: options?.signal, bodyParameters: {emailSignInDto}}),
    },

};

export default Api;
